<template>
  <UiSliderHeroNew
    v-if="props.slides?.length"
    :slides-count="props.slides.length"
    class="!w-screen lg:!w-full"
  >
    <UiSliderHeroSingleSlide
      v-for="(slide, slideIndex) of props.slides"
      :key="slideIndex"
      :title="slide.header"
      :subtitle="slide.subheader"
      :link="slide.link?.href"
      :link-text="slide.linkText"
    >
      <picture>
        <source
          v-for="source of createSources(slide)"
          :key="source.src"
          :srcset="source.src"
          :media="createMediaQuery(source.media.maxWidth, 'max')"
        >
        <UiImage
          :src="getImageData(slide, 'desktop', 'url')"
          :original-width="getImageData(slide, 'desktop', 'width')"
          :original-height="getImageData(slide, 'desktop', 'height')"
          width="1920"
          densities="x1"
          :loading="slideIndex === 0 ? 'eager' : 'lazy'"
          :fetchpriority="slideIndex === 0 ? 'high' : 'low'"
          decoding="async"
          class="size-full object-cover object-center"
        />
      </picture>
    </UiSliderHeroSingleSlide>
  </UiSliderHeroNew>
</template>

<script lang="ts" setup>
import type { Link } from '@unhead/schema'
import UiSliderHeroSingleSlide from '@ui/components/UiSlider/UiSliderHeroSingleSlide.vue'
import UiSliderHeroNew from '@ui/components/UiSlider/UiSliderHeroNew.vue'
import type {
  Content,
  CropVariant,
  Dimensions,
  File,
} from '@ui/components/UiSlider/UiSliderHero.types'
import UiImage from '@ui/components/UiImage/UiImage.vue'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps<{
  slides: Content[] | null
}>()

const $img = useImage()
const firstSlide = ref(props.slides?.[0])

function createSources(slide: Content) {
  const imageDesktop = getImageData(slide, 'desktop', 'url')
  const imageMobile = getImageData(slide, 'mobile', 'url')
  const sharedSettings = { q: 75, fit: 'cover' }
  const sources = [
    {
      src: imageMobile && $img(imageMobile, { width: 750, height: 500, ...sharedSettings }),
      media: { maxWidth: 500 },
    },
    {
      src: imageMobile && $img(imageMobile, { width: 768, height: 512, ...sharedSettings }),
      media: { minWidth: 501, maxWidth: 767 },
    },
    {
      src: imageMobile && $img(imageMobile, { width: 984, height: 656, ...sharedSettings }),
      media: { minWidth: 768, maxWidth: 984 },
    },
    {
      src: imageDesktop && $img(imageDesktop, { width: 1440, height: 644, ...sharedSettings }),
      media: { minWidth: 984, maxWidth: 1200 },
    },
    {
      src: imageDesktop && $img(imageDesktop, { width: 1920, height: 858, ...sharedSettings }),
      media: { minWidth: 1201 },
    },
  ]
  return sources
}

function createMediaQuery(value: number | undefined, type: 'min' | 'max'): string | undefined {
  if (!value) { return }
  return `(${type}-width: ${value}px)`
}

function getImageData(
  slide: Content,
  variant: 'desktop' | 'mobile' | 'thumb',
  data: 'url' | 'width' | 'height',
): string {
  const imageDesktop = slide.file as File
  const imageMobile = slide.fileMobile as File
  const imageThumbnail = slide.fileThumbnail as File

  if (variant === 'desktop' && imageDesktop?.url) {
    if (data === 'url') {
      return imageDesktop.url
    }
    if (data === 'width' || data === 'height') {
      return _returnDimension(imageDesktop, data) ?? '1920'
    }
  }

  // if there is a special image for mobile
  if (variant === 'mobile' && imageMobile?.url) {
    if (data === 'url') {
      return imageMobile.url
    }
    if (data === 'width' || data === 'height') {
      return _returnDimension(imageMobile, data) ?? '640'
    }
  }

  // if there is no special image for mobile, but there is a cropped variant of desktop image
  if (variant === 'mobile' && imageDesktop?.cropVariants?.mobile?.url) {
    if (data === 'url') {
      return imageDesktop.cropVariants.mobile.url
    }
    if (data === 'width' || data === 'height') {
      return _returnDimension(imageDesktop.cropVariants.mobile, data) ?? '640'
    }
  }

  if (variant === 'thumb' && imageThumbnail?.url) {
    if (data === 'url') {
      return imageThumbnail.url
    }
    if (data === 'width' || data === 'height') {
      return _returnDimension(imageDesktop.cropVariants.mobile, data) ?? '130'
    }
  }

  function _returnDimension(
    image: File | CropVariant,
    dimension: keyof Dimensions,
  ): string | null {
    if (image?.properties?.dimensions?.[dimension]) {
      return String(image.properties.dimensions[dimension])
    }
    return null
  }

  return imageDesktop?.url ?? ''
}

const firstSlidePreloadLinks = computed(() => {
  if (!firstSlide.value) { return }
  const sources = createSources(firstSlide.value)
  const preload: Link[] = []
  sources.forEach((source, index) => {
    preload.push({
      rel: 'preload',
      as: 'image',
      href: source.src,
      media: Object.entries(source.media).map((sourceMedia) => {
        const [variant, value] = sourceMedia
        const media = []
        if (variant === 'minWidth') {
          media.push(`(min-width: ${value}px)`)
        }
        if (variant === 'maxWidth') {
          media.push(`(max-width: ${value}px)`)
        }
        return media
      }).join(' and '),
      key: `hero-slider-preload-${index}`,
    })
  })
  return preload
})

if (firstSlide.value) {
  useHead({
    link: firstSlidePreloadLinks.value,
  })
}
</script>
